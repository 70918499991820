/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { ProjectData } from '../../constants'

import LogoImg from '../../assets/images/logo.svg'
import TreasuryBalanceBox from '../TreasuryBalanceBox'
import { useAccount } from 'wagmi'
import UserEggBalance from '../UserEggBalance'
import { useQuery } from 'urql'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useContracts } from '../../hooks/useContracts'
import MetaMaskAddToken from '../MetaMaskAddToken'
import moment from 'moment'

const NavbarNew = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const { address, isConnected } = useAccount()

  // Get users farmAnimals balance

  const [hasTokens, setHasTokens] = useState(false)
  const [publicMinting, setPublicMinting] = useState(false)

  const currentTimestamp = moment().unix()

  const GetUserTokens = `
		query GetUserTokens(
			$accountAddress: String!,
			$farmAnimalsContract: String!,
			$imperialEggsContract: String!,
			$eggShopContract: String!
			) @live(interval: 10000) {
			account(id: $accountAddress) {
				farmAnimals: nftTokens(where: {contract: $farmAnimalsContract}) {
					id
					token {
						id
						tokenId
						kind
					}
				}
				eggShop: nftTokens(where: {contract: $eggShopContract}) {
					id
					quantity
					token {
						id
						tokenId
					}
				}
				imperialEggs: nftTokens(where: {contract: $imperialEggsContract}) {
					id
					token {
						id
						tokenId
					}
				}
				stakedTokens(orderBy: kind) {
					id
					tokenId
					kind
				}
			}
			tfgdata(id: "season-1") {
				publicTime
			}
		}
	`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: GetUserTokens,
    variables: {
      accountAddress: address?.toLowerCase(),
      imperialEggsContract: ContractAddresses.ImperialEggsContractAddress,
      farmAnimalsContract: ContractAddresses.FarmAnimalsContractAddress,
      eggShopContract: ContractAddresses.EggShopContractAddress,
    },
    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  useEffect(() => {
    if (urqlData) {
      if (urqlData.account && urqlData.account.farmAnimals) {
        if (urqlData.account.farmAnimals.length > 0) setHasTokens(true)
      }
      if (urqlData.account && urqlData.account.eggShop) {
        if (urqlData.account.eggShop.length > 0) setHasTokens(true)
      }
      if (urqlData.account && urqlData.account.imperialEggs) {
        if (urqlData.account.imperialEggs.length > 0) setHasTokens(true)
      }
      if (urqlData.account && urqlData.account.stakedTokens) {
        if (urqlData.account.stakedTokens.length > 0) setHasTokens(true)
      }
      if (urqlData.tfgdata && urqlData.tfgdata.publicTime) {
        if (urqlData.tfgdata.publicTime < currentTimestamp) {
          setPublicMinting(true)
        }
      }
    }
  }, [urqlData])

  const showInventory = true

  return (
    <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand href='/'>
          <img src={LogoImg} alt='TFG' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <TreasuryBalanceBox />
          <Nav className='me-auto'>
            <Link className='nav-link' to='/'>
              Story
            </Link>
            <Link className='nav-link' to='/mint'>
              Mint
            </Link>
            <Link className='nav-link' to='/wallet_check'>
              Whitelist
            </Link>
            {isConnected && hasTokens && showInventory && (
              <>
                <Link className='nav-link' to='/henhouse'>
                  Houses
                </Link>
                {/* <Link className='nav-link' to='/eggshop'>
                  Shop
                </Link> */}
                <Link className='nav-link' to='/inventory'>
                  Inventory
                </Link>
              </>
            )}

            <Link className='nav-link' to='/stats'>
              Stats
            </Link>

            <Link className='nav-link' to='/dao'>
              DAO
            </Link>
            <Nav.Link href='https://docs.thefarm.game'>Docs</Nav.Link>
          </Nav>
          <Nav>
            {ProjectData.socials.map(
              (item, index) =>
                item.url && (
                  <Nav.Link href={item.url} target='_blank' rel='noreferrer' key={index}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox={item.svgViewBox} height={32} width={32}>
                      <path fill='#ffffff' d={item.svgData} className=''></path>
                    </svg>
                  </Nav.Link>
                ),
            )}
            <Nav>
              <MetaMaskAddToken />
            </Nav>
            <Nav.Item>
              {isConnected && (
                <div className='userBalance__card'>
                  <UserEggBalance />
                </div>
              )}
            </Nav.Item>
            <Nav.Item>
              <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarNew

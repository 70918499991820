import React from 'react'

const GasLimitNote = (): JSX.Element => {
  return (
    <>
      <h4>
        * We use Commit/Reveal method to keep the game fair for all players.
        <br /> This also allows you to save gas on reveal, as you can wait for gas to go down to reveal
        <br />
        <br />- Commit: Fee paid and seed are assigned to your wallet
        <br />- Reveal: After ~15mins your seed is used to generate random character/s
        <br />- Game: Rewards & game start once you reveal
        <br />- Both tx require gas
      </h4>
      <br />
      <h4>
        ** Gas limit is over estimated by 30% to account for randomness. You will most likely not be charged the full
        gas amount shown in your wallet
      </h4>
    </>
  )
}

export default GasLimitNote

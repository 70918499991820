import React, { useState, useEffect } from 'react'

import TabImg2 from '../../assets/images/chick_1.png'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount, useContractReads } from 'wagmi'
import { ethers } from 'ethers'

import { TFGSalesDataProps } from '../../types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonContractWrite from '../ButtonContractWrite'
import GasLimitNote from '../GasLimitNote'

import MintingCharacter from '../../assets/images/minting_animations/new_character_mint.gif'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
  pendingMintQty: number
}

const MintGen0 = (props: PropTypes): JSX.Element => {
  const { TFGSalesData, pendingMintQty } = props

  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const FarmGameMintContractAddress = ContractAddresses.TheFarmGameMintContractAddress

  const { address, isConnected } = useAccount()

  const [mintQty, setMintQty] = useState(1)
  const [maxQty, setMaxQty] = useState(TFGSalesData.publicMaxPerTx)

  const [pauseWatch, setPauseWatch] = useState(false)

  const handlePauseWatch = () => {
    setPauseWatch(true)
  }

  const handleRevealSuccess = (data: any) => {
    setPauseWatch(false)
    setMintQty(1)
    updateMintAmount(true)
  }

  // Gen 0

  const [mintPublicSaleTotal, setMintPublicSaleTotal] = useState(TFGSalesData.publicFee)

  const [mintPublicSaleStakeTotal, setMintPublicSaleStakeTotal] = useState(TFGSalesData.publicStakeFee)

  const [userUnstakedBalance, setUserUnStakedBalance] = useState(0)
  const [userStakedBalance, setUserStakedBalance] = useState(0)

  const [hasNoPendingMints, setHasNoPendingMints] = useState(false)
  const [canReveal, setCanReveal] = useState(false)

  // FarmAnimals Balance check
  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }
  const henHouseConfig = {
    address: ContractAddresses.HenHouseContractAddress,
    abi: ContractABIs.HenHouseABI,
  }

  const theFarmAnimalMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  useContractReads({
    contracts: [
      { ...farmAnimalsConfig, functionName: 'balanceOf', args: [address] },
      { ...henHouseConfig, functionName: 'getStakedByAddress', args: [address?.toLowerCase()] },
      { ...theFarmAnimalMintConfig, functionName: 'hasMintPending', args: [address?.toLowerCase()] },
      { ...theFarmAnimalMintConfig, functionName: 'canReveal', args: [address?.toLowerCase()] },
    ],
    watch: true,
    onSuccess(data) {
      if (data) {
        // Unstaked
        const unStakedBalance = data[0] ? parseInt(data[0].toString()) : 0

        setUserUnStakedBalance(unStakedBalance)

        // Staked
        const stakedBalance = data[1] ? data[1].length : 0
        setUserStakedBalance(stakedBalance)

        setHasNoPendingMints(!data[2])

        const canRevealResult = data[3] as unknown as boolean
        setCanReveal(canRevealResult)
      }
    },
  })

  const updateMintAmount = (status: boolean) => {
    if (TFGSalesData) {
      if (status && mintQty) {
        setMintQty(mintQty + 1)
        setMintPublicSaleTotal((mintQty + 1) * TFGSalesData.publicFee)
        setMintPublicSaleStakeTotal((mintQty + 1) * TFGSalesData.publicStakeFee)
      } else if (!status && mintQty > 1) {
        setMintQty(mintQty - 1)
        setMintPublicSaleTotal((mintQty - 1) * TFGSalesData.publicFee)
        setMintPublicSaleStakeTotal((mintQty - 1) * TFGSalesData.publicStakeFee)
      }
    }
  }

  useEffect(() => {
    if (TFGSalesData.saleStatus === 'GEN 0') {
      if (mintQty === 1) {
        setMintPublicSaleTotal(TFGSalesData.publicFee)
        setMintPublicSaleStakeTotal(TFGSalesData.publicStakeFee)
        setMintQty(1)
      }

      if (TFGSalesData.totalSupply + TFGSalesData.publicMaxPerTx + pendingMintQty < TFGSalesData.maxGen0Supply) {
        setMaxQty(TFGSalesData.publicMaxPerTx)
      } else {
        setMaxQty(TFGSalesData.maxGen0Supply - TFGSalesData.totalSupply - pendingMintQty)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TFGSalesData])

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <div className='col-xl-5 col-lg-6'>
      <div className='tab__card'>
        <div className='tab__text'>
          {/* <h1>MINTING {TFGSalesData?.saleStatus}</h1> */}

          <p>
            Gen 0 Supply: {TFGSalesData.totalSupply + pendingMintQty}/{TFGSalesData.maxGen0Supply}
          </p>

          {(userUnstakedBalance > 0 || userStakedBalance > 0) && (
            <p>You own {userUnstakedBalance + userStakedBalance} animals</p>
          )}
        </div>

        <div className='btn__box'>
          {isConnected ? (
            <>
              {TFGSalesData.totalSupply + pendingMintQty >= TFGSalesData.maxGen0Supply ? (
                <>
                  <h1>Sold out</h1>
                </>
              ) : (
                <>
                  {hasNoPendingMints && (
                    <div className='in__zoom'>
                      <div className='in__content'>
                        <button className='btn-circle' onClick={() => updateMintAmount(false)}>
                          <FontAwesomeIcon className='btn_qty brown' icon={faMinus} />
                        </button>
                        <div className='in__zoom__first'>
                          <img src={TabImg2} alt='' />
                        </div>
                        <button
                          className='btn-circle'
                          disabled={mintQty === maxQty}
                          onClick={() => updateMintAmount(true)}
                        >
                          <FontAwesomeIcon className='brown' icon={faPlus} />
                        </button>
                      </div>
                      <h2>
                        mint <span className='brown'>{mintQty}</span> for {mintPublicSaleTotal.toFixed(3)} ETH
                      </h2>
                      <br />
                      <h2>
                        mint & Stake <span className='brown'>{mintQty}</span> for {mintPublicSaleStakeTotal.toFixed(3)}{' '}
                        ETH
                      </h2>
                    </div>
                  )}

                  {TFGSalesData.totalSupply < TFGSalesData.maxGen0Supply && hasNoPendingMints && (
                    <>
                      <div className='btn__card'>
                        <ButtonContractWrite
                          ContractAddress={FarmGameMintContractAddress}
                          ContractABI={ContractABIs.TheFarmGameMintABI}
                          ContractFunction='mintCommitGen0'
                          ContractArgs={[mintQty, false]}
                          ContractOverRides={
                            mintPublicSaleTotal < 5 && {
                              value: ethers.utils.parseEther(mintPublicSaleTotal.toString()),
                              // gasLimit: mintGasLimit,
                            }
                          }
                          ContractIsEnabled={mintPublicSaleTotal > 0 && hasNoPendingMints}
                          ButtonTextPrepareIdle={`Check for Hens`}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={`Mint Commit`}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Hatching hens...'}
                          ButtonTextError={"Couldn't Hatch any Hens"}
                          StartAlertText={'Incubating Hen Eggs!'}
                          StartAlertOptions={{ icon: '🥚' }}
                          SuccessAlertText={'Hatched a Hen!'}
                          SuccessAlertOptions={{ icon: '🥚' }}
                          // OnSuccessFunction={handleMintSuccess}
                          // imageWaitTx={MintingCharacter}
                        />
                        <ButtonContractWrite
                          ContractAddress={FarmGameMintContractAddress}
                          ContractABI={ContractABIs.TheFarmGameMintABI}
                          ContractFunction='mintCommitGen0'
                          ContractArgs={[mintQty, true]}
                          ContractOverRides={
                            mintPublicSaleStakeTotal < 5 && {
                              value: ethers.utils.parseEther(mintPublicSaleStakeTotal.toString()),
                              // gasLimit: mintStakeGasLimit,
                            }
                          }
                          ContractIsEnabled={mintPublicSaleStakeTotal > 0 && hasNoPendingMints}
                          ButtonTextPrepareIdle={`Check for Hens`}
                          ButtonTextPrepareLoading={`Preparing`}
                          ButtonTextInitial={`Mint & Stake Commit`}
                          ButtonTextLoading={'Waiting for approval'}
                          ButtonTextWriting={'Hatching hens...'}
                          ButtonTextError={"Couldn't Hatch any Hens"}
                          StartAlertText={'Incubating Hen Eggs!'}
                          StartAlertOptions={{ icon: '🥚' }}
                          SuccessAlertText={'Moved into Hen House!'}
                          SuccessAlertOptions={{ icon: '🥚' }}
                          // imageWaitTx={MintingCharacter}
                        />
                      </div>
                      <h4>Gen 0 minting limited to 5 per tx</h4>
                      <br />
                      <GasLimitNote />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className='btn__box'>
              <div className='btn__card btn__anchor'>
                <ConnectButton chainStatus='none' accountStatus='avatar' showBalance={false} />
              </div>
            </div>
          )}
        </div>

        {isConnected && !hasNoPendingMints && (
          <div className='btn__box'>
            <h1>You have pending mints</h1>
            <h1>Reveal opens every ~15mins</h1>
            <div className='btn__card' onClick={handlePauseWatch}>
              <ButtonContractWrite
                ContractAddress={FarmGameMintContractAddress}
                ContractABI={ContractABIs.TheFarmGameMintABI}
                ContractFunction='mintReveal'
                ContractArgs={[]}
                ContractOverRides={{}}
                ContractIsEnabled={canReveal && !hasNoPendingMints}
                ButtonTextPrepareIdle={`No peeking`}
                ButtonTextPrepareLoading={`Preparing`}
                ButtonTextInitial={`Reveal Animals`}
                ButtonTextLoading={'Waiting for approval'}
                ButtonTextWriting={'Hatching hens...'}
                ButtonTextError={"Couldn't Hatch any Hens"}
                StartAlertText={'Incubating Hen Eggs!'}
                StartAlertOptions={{ icon: '🥚' }}
                SuccessAlertText={'Hatched a Hen!'}
                SuccessAlertOptions={{ icon: '🥚' }}
                OnSuccessFunction={handleRevealSuccess}
                imageWaitTx={MintingCharacter}
              />
            </div>
            <GasLimitNote />
          </div>
        )}
      </div>
    </div>
  )
}

export default MintGen0

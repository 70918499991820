import { useEffect, useState } from 'react'
import { useQuery } from 'urql'

import MintFarmAnimals from '../../components/MintMain'
import MintCountdown from '../../components/MintCountdown'
import SpecialMintRewards from '../../components/SpecialMintRewards'

import { Address, useAccount, useBlockNumber, useContractReads, useProvider } from 'wagmi'
import { ethers } from 'ethers'
import { TFGSalesDataProps } from '../../types'
import { useContractABIs } from '../../hooks/useContractsABI'
import { useContracts } from '../../hooks/useContracts'

const MintPage = (): JSX.Element => {
  const ContractAddresses = useContracts()
  const ContractABIs = useContractABIs()

  const { address } = useAccount()

  const provider = useProvider()

  const [TFGSalesData, setTFGSalesData] = useState<TFGSalesDataProps>({
    saleStatus: 'loading',
    preSaleTime: 0,
    allowListTime: 0,
    preSaleFee: 0,
    preSaleStakeFee: 0,
    preSaleTokens: 0,
    preSaleMaxQty: 0,
    publicTime: 0,
    publicFee: 0,
    publicStakeFee: 0,
    publicMaxPerTx: 0,
    eggMintFee: 0,
    totalSupply: 0,
    maxSupply: 0,
    maxGen0Supply: 0,
  })

  // get live Sales status
  const theFarmGameMintConfig = {
    address: ContractAddresses.TheFarmGameMintContractAddress,
    abi: ContractABIs.TheFarmGameMintABI,
  }

  const farmAnimalsConfig = {
    address: ContractAddresses.FarmAnimalsContractAddress,
    abi: ContractABIs.FarmAnimalsABI,
  }

  useContractReads({
    contracts: [
      { ...theFarmGameMintConfig, functionName: 'getSaleStatus' },
      { ...theFarmGameMintConfig, functionName: 'preSaleTime' },
      { ...theFarmGameMintConfig, functionName: 'allowListTime' },
      { ...theFarmGameMintConfig, functionName: 'preSaleFee' },
      { ...theFarmGameMintConfig, functionName: 'preSaleStakeFee' },
      { ...theFarmGameMintConfig, functionName: 'preSaleMaxQty' },
      { ...theFarmGameMintConfig, functionName: 'publicTime' },
      { ...theFarmGameMintConfig, functionName: 'publicFee' },
      { ...theFarmGameMintConfig, functionName: 'publicStakeFee' },
      { ...theFarmGameMintConfig, functionName: 'publicMaxPerTx' },
      { ...theFarmGameMintConfig, functionName: 'currentPriceToMint' },
      { ...farmAnimalsConfig, functionName: 'totalSupply' },
      { ...farmAnimalsConfig, functionName: 'maxSupply' },
      { ...farmAnimalsConfig, functionName: 'maxGen0Supply' },
    ],
    overrides: { from: '0xABf196a087a5F7d994f41BfEa0Ba1F55e96566C5' },
    watch: true,
    // isDataEqual: (prev, next) => prev === next,
    onSuccess(data) {
      setTFGSalesData((prevState) => ({
        ...prevState, // keep all other key-value pairs
        saleStatus: data[0] ? data![0].toString() : 'soon',
        preSaleTime: data[1] ? parseInt(data![1].toString()) : 0,
        allowListTime: data[2] ? parseInt(data![2].toString()) : 0,
        preSaleFee: data[3] ? parseFloat(ethers.utils.formatEther(data![3])) : 0,
        preSaleStakeFee: data[4] ? parseFloat(ethers.utils.formatEther(data![4])) : 0,
        preSaleMaxQty: data[5] ? parseInt(data![5].toString()) : 0,
        preSaleTokens: 0,
        publicTime: data[6] ? parseInt(data![6].toString()) : 0,
        publicFee: data[7] ? parseFloat(ethers.utils.formatEther(data![7])) : 0,
        publicStakeFee: data[8] ? parseFloat(ethers.utils.formatEther(data![8])) : 0,
        publicMaxPerTx: data[9] ? parseInt(data![9].toString()) : 0,
        eggMintFee: data[10] ? parseFloat(ethers.utils.formatEther(data![10])) : 0,
        totalSupply: data[11] ? parseInt(data![11].toString()) : 0,
        maxSupply: data[12] ? parseInt(data![12].toString()) : 0,
        maxGen0Supply: data[13] ? parseInt(data![13].toString()) : 0,
      }))
    },
    onError(error) {
      console.error('MintPage.tsx useContractReads Error', error)
    },
  })

  const [blockNumber, setBlockNumber] = useState(0)
  const [blockTimeStamp, setBlockTimeStamp] = useState(0)

  useBlockNumber({
    onBlock(blockNumber) {
      setBlockNumber(blockNumber)
    },
  })

  useEffect(() => {
    const fetchBlockInfo = async () => {
      if (blockNumber > 0) {
        const blockInfo = await provider.getBlock(blockNumber)
        setBlockTimeStamp(blockInfo.timestamp)
      }
    }

    fetchBlockInfo().catch(console.error)
  }, [blockNumber, provider])

  const GetPendingMintQty = `
	query MyQuery {
		tfgdata(id: "season-1") {
			pendingMintQty
		}
	}
	`

  // useQuery urql

  const [result, reexecuteQuery] = useQuery({
    query: GetPendingMintQty,

    // pause: loadingCompleted,
  })

  const { data: urqlData, fetching: urqlFetching, error: urqlError } = result

  const [pendingMintQty, setPendingMintQty] = useState(0)

  useEffect(() => {
    if (result.fetching) return

    // Set up to refetch in 20 seconds, if the query is idle
    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' })
    }, 20000)
    // console.log(`🚀 ~ timerId`, timerId)

    return () => clearTimeout(timerId)
  }, [result.fetching, reexecuteQuery])

  useEffect(() => {
    if (urqlData) {
      if (urqlData.tfgdata && urqlData.tfgdata.pendingMintQty) {
        setPendingMintQty(urqlData.tfgdata.pendingMintQty)
      }
    }
  }, [urqlData])

  if (!TFGSalesData) {
    return (
      <div>
        <section className='mint--section'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='egg-shop__head'>
                <h1>Mint Loading...</h1>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className=''>
      <section className='mint--section '>
        <div className='container vh-80'>
          <div className='row justify-content-center'>
            <div className='egg-shop__head'>
              <h1>Minting {TFGSalesData.saleStatus}</h1>
              {/* {!isConnected && <h1>PreSale at 11AM EST</h1>} */}
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                {/* {TFGSalesData.saleStatus !== 'paused' && TFGSalesData.saleStatus !== 'soon' && (
                  <MintProgressBar
                    totalSupply={TFGSalesData.totalSupply}
                    maxSupply={TFGSalesData.maxSupply}
                    maxGen0Supply={TFGSalesData.maxGen0Supply}
                  />
                )} */}
                <div className='row align-content-center'>
                  {TFGSalesData.saleStatus === 'soon' && (
                    <MintCountdown
                      timeToSale={TFGSalesData.preSaleTime}
                      blockTimeStamp={blockTimeStamp}
                      nextSaleStatus='presale'
                    />
                  )}
                  {TFGSalesData.saleStatus === 'presale' && (
                    <MintCountdown
                      timeToSale={TFGSalesData.allowListTime}
                      blockTimeStamp={blockTimeStamp}
                      nextSaleStatus='allow list'
                    />
                  )}
                  {TFGSalesData.saleStatus === 'allowlist' && (
                    <MintCountdown
                      timeToSale={TFGSalesData.publicTime}
                      blockTimeStamp={blockTimeStamp}
                      nextSaleStatus='Public'
                    />
                  )}
                </div>
                <div className='card-body h-auto'>
                  <div className='row justify-content-center'>
                    <MintFarmAnimals
                      TFGSalesData={TFGSalesData}
                      blockTimeStamp={blockTimeStamp}
                      pendingMintQty={pendingMintQty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {TFGSalesData.saleStatus !== 'paused' &&
        TFGSalesData.saleStatus !== 'soon' &&
        TFGSalesData.saleStatus !== 'presale' && <SpecialMintRewards TFGSalesData={TFGSalesData} />}
    </div>
  )
}

export default MintPage

import React from 'react'

import { TFGSalesDataProps } from '../../types'

import MintPreSale from './MintPreSale'
import MintGen0 from './MintGen0'
import MintGen1 from './MintGen1'
import MintPaused from './MintPaused'
import MintAllowList from './MintAllowList'
import MintSoon from './MintSoon'
import MintLoading from './MintLoading'

interface PropTypes {
  TFGSalesData: TFGSalesDataProps
  blockTimeStamp: EpochTimeStamp
  pendingMintQty: number
}

const MintMain = (props: PropTypes): JSX.Element => {
  const { TFGSalesData, blockTimeStamp, pendingMintQty } = props

  if (!TFGSalesData) {
    return (
      <div className='loading--section'>
        <h1>Minting Loading...</h1>
      </div>
    )
  }

  return (
    <>
      {TFGSalesData.saleStatus === 'loading' && <MintLoading TFGSalesData={TFGSalesData} />}
      {TFGSalesData.saleStatus === 'paused' && <MintPaused TFGSalesData={TFGSalesData} />}
      {TFGSalesData.saleStatus === 'soon' && <MintSoon TFGSalesData={TFGSalesData} />}
      {TFGSalesData.saleStatus === 'allowlist' && (
        <MintAllowList TFGSalesData={TFGSalesData} pendingMintQty={pendingMintQty} />
      )}
      {TFGSalesData.saleStatus === 'presale' && (
        <MintPreSale TFGSalesData={TFGSalesData} pendingMintQty={pendingMintQty} />
      )}
      {TFGSalesData.saleStatus === 'GEN 0' && <MintGen0 TFGSalesData={TFGSalesData} pendingMintQty={pendingMintQty} />}
      {(TFGSalesData.saleStatus === 'GEN 1' ||
        TFGSalesData.saleStatus === 'GEN 2' ||
        TFGSalesData.saleStatus === 'GEN 3' ||
        TFGSalesData.saleStatus === 'GEN 4' ||
        TFGSalesData.saleStatus === 'GEN 4') && <MintGen1 TFGSalesData={TFGSalesData} />}
    </>
  )
}

export default MintMain
